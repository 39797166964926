import React from "react"

import AltLayout from "../components/altlayout"
import SEO from "../components/seo"

import LotFour from "../components/Lots/lotfour"
import Footer from "../components/Footer/footer"


const LotFourPage = () => (
  <AltLayout>
    <SEO title="Lot" />
    <LotFour/>
    <Footer />
  </AltLayout>
)

export default LotFourPage
